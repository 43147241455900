import {createEffect} from "effector";
import {ObjectType} from "shared/helpers/types";
import {
    authURI,
    checkAuth,
    logout,
    resendCodeConfirmation,
    resetPassword,
    sendCode,
    sendVerificationEmailCode,
    signIn,
    signUp,
    validateCode
} from "./services";
import {resetAuthState} from ".";
import {fetchUserAttachmentsByID} from "../user/attachments/services";

export const signInFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await signIn(body);

        if (!response?.data) throw new Error('');
        if (!response?.data?.signin?.user?.verifyEmail) await resendCodeConfirmation({email: response?.data?.signin?.user.email})
        return response?.data?.signin?.user || {}

    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const signInWithLinkedInFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await signIn(body);
        if (!response?.data) throw new Error('');
        return response?.data?.signin?.user || {};
    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const checkAuthFx = createEffect(async () => {
    try {
        const response = await checkAuth();
        return response?.data?.checkAuth?.user || {};
    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const logoutFx = createEffect(async () => {
    try {
        const response = await logout();
        resetAuthState()
        return response?.data || false;
    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const fetchAuthUserAttachmentsByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUserAttachmentsByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.attachmentByID
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const sendCodeFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await sendCode(body);
        if (response?.data?.sendCode) return body.email;
        return '';
    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const validateCodeFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await validateCode(body);
        return response?.data?.validateCode;
    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const resetPasswordFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await resetPassword(body);
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.resetPassword;
    } catch (error: any) {
        throw new Error(error?.message);
    }
})

export const authLinkedInFx = createEffect(async () => {
    try {
        const response = await authURI();
        if (!response?.data) {
            throw new Error('No data received from LinkedIn auth URI');
        }

        return response.data.getAuthUri || '';
    } catch (error: any) {
        console.error('Error fetching LinkedIn auth URI:', error.message);
        throw new Error(error.message || 'Failed to fetch LinkedIn auth URI');
    }
})

export const authGoogleFx = createEffect(async () => {
    try {
        const response = await authURI()
        if (!response?.data) throw new Error('')


        return response?.data?.getAuthUri || ''
    } catch (error: any) {
        throw new Error(error?.message)
    }
})

export const signUpFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await signUp(body)

        if (!response?.data) throw new Error(response.errors[0].message)
        return response?.data?.userRegistration || {}
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error?.message)
        }
    }
})

export const sendVerificationEmailCodeFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await sendVerificationEmailCode(body);
        if (!response?.data) throw new Error(response.errors[0].message)
        return response?.data?.verifyUserEmail
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error?.message)
        }
    }
})

export const reSendVerifiedEmailCodeFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await resendCodeConfirmation(body)
        if (response?.data?.resendCodeConfirmation) return body.email
        return '';
    } catch (error) {
        if (error instanceof Error) {
            throw new Error(error?.message)
        }
    }
})
