import { fetchAccessGQL } from "queries/global"
import { Base_Url } from "shared/helpers/api-routes"
import { ObjectType } from "shared/helpers/types"
import { BaseApi } from "utils/Api/baseApi"

export const fetchAccessValue = (body: ObjectType) => {
  const http = new BaseApi('')
  const gqlPayload = {
      query: fetchAccessGQL,
      variables: body
  }
  return http.post(Base_Url, gqlPayload)
}
