import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import enMessages from 'lang/en.json'
import { IntlProvider } from 'react-intl'

import App from './App'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { BASE_NAME } from 'shared/helpers/api-routes'

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)
const locale = 'en'

root.render(
    <IntlProvider locale={locale} messages={enMessages}>
        <BrowserRouter basename={BASE_NAME}>
            <App />
            <ToastContainer />
        </BrowserRouter>
    </IntlProvider>
)