import { Box, useMediaQuery, Theme } from '@mui/material'
import Profile from './Profile'
import MobileSection from './MobileSection'

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box sx={{display: 'flex', justifyContent: 'end', width: '100%'}}>
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </Box>
  )
}

export default HeaderContent