import { fetchInsurerAvatarByIDGQL } from "queries/insurers";
import { Base_Url } from "shared/helpers/api-routes";
import { ObjectType } from "shared/helpers/types";
import { BaseApi } from "utils/Api/baseApi";

export const fetchInsurerAvatarByID = (body: ObjectType) => {
  const http = new BaseApi('');
  const gqlPayload = {
    query: fetchInsurerAvatarByIDGQL,
    variables: body
  };
  return http.post(Base_Url, gqlPayload)
}