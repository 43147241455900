import {createTheme} from '@mui/material/styles'
import {presetPalettes} from '@ant-design/colors'
import ThemeOption from '../themes/theme'

type Mode = 'light' | 'dark'

const Palette = (mode: Mode) => {
    const colors = presetPalettes

    const greyPrimary = [
        '#3D6789',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ]

    const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f']
    const greyConstant = ['#FFF', '#3D6789']

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant]

    const paletteColor = ThemeOption(colors as any)

    return createTheme({
        palette: {
            mode,
            common: {
                white: '#fff'
            },
            ...paletteColor,
            text: {
                primary: '#0C1231',
                secondary: paletteColor.grey[500],
                disabled: paletteColor.grey[400]
            },
            action: {
                disabled: paletteColor.grey[300]
            },
            divider: paletteColor.grey[200],
            background: {
                default: paletteColor.grey.A50
            }
        }
    })
}

export default Palette