import {Box} from '@mui/material'
import NavGroup from './NavGroup'
import menuItem from 'menu-items'

const Navigation = () => {
    
    const navGroups = menuItem.items.map((item: Record<string, any>, idx: number) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={idx} item={item}/>
        }
        return <></>
    })

    return <Box>{navGroups}</Box>
}

export default Navigation