import {createEffect} from "effector"
import {ObjectType} from "shared/helpers/types"
import {fetchUserAttachmentsByID} from "./services"

export const fetchUserAttachmentsByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUserAttachmentsByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.attachmentByID
    } catch (error: any) {
        throw new Error(error.message)
    }
})