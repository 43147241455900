import React, {forwardRef, useEffect, useMemo, useState} from 'react'
import {Link, useLocation} from 'react-router-dom'
import {Box, ListItemButton, ListItemText, Typography} from '@mui/material'
import {MenuItem} from './NavGroup'
import {useUnit} from 'effector-react'
import {$menuState, activeItem} from 'store/models/menu'

interface NavItemProps {
    item: MenuItem
    level: number
}

const NavItem: React.FC<NavItemProps> = ({
    item,
    level,
}) => {
    const {pathname} = useLocation()

    const {drawerOpen, openItem} = useUnit($menuState)

    const [isOpenChilds, setIsOpenChilds] = useState<boolean>(false)
    const isSelected = openItem?.findIndex((id: string) => id === item.id) > -1
    const iconSelectedColor = 'primary.main'

    let itemTarget = item.target ? '_blank' : '_self'

    let listItemProps: { component: React.ElementType; to?: string; href?: string; target?: string } = {
        component: forwardRef<HTMLAnchorElement | HTMLDivElement, any>((props, ref) => (
            item?.isClickable ?
                <div ref={ref} {...props} target={itemTarget}/>
                : item?.external
                    // default &nbsp; value needs to put default value into Anchor
                    ? <a ref={ref} {...props} href={item.url} target={itemTarget}>&nbsp;</a>
                    : <Link ref={ref} {...props} to={item.url} target={itemTarget}/>
        ))
    }

    useEffect(() => {
        if (pathname.includes(item.url)) {
            activeItem([item.id])
        }
    }, [pathname])

    useMemo(() => {
        if (!isSelected && isOpenChilds) setIsOpenChilds(false)
    }, [openItem])

    const itemHandler = (id: string, isClickable = false) => {
        if (isClickable) setIsOpenChilds(!isOpenChilds)
        activeItem([id])
    }

    const renderChilds = () => {
        if (!item?.children?.length) return null
        return item.children?.map((menuItem: MenuItem, idx: number) => {
            switch (menuItem.type) {
                case 'item':
                    return <NavItem
                        key={idx}
                        item={menuItem}
                        level={2}
                    />
                default:
                    return null
            }
        })
    }

    const checkPath = () => item.children?.some(it => pathname.includes(it.url))

    return (
        <>
            <ListItemButton
                {...listItemProps}
                onClick={() => itemHandler(item.id, item.isClickable)}
                disabled={item.disabled}
                selected={isSelected || checkPath()}
                aria-hidden="false"
                sx={{
                    zIndex: 1201,
                    pl: drawerOpen ? `${level * 28}px` : 1.5,
                    py: !drawerOpen && level === 1 ? 1.25 : 1,
                    ...(drawerOpen && {
                        '&.Mui-selected': {
                            bgcolor: level === 1 ? 'primary.lighter' : 'lightgrey',
                            color: iconSelectedColor,
                            '&:hover': {
                                color: iconSelectedColor,
                                bgcolor: level === 1 ? 'primary.lighter' : 'lightgrey'
                            }
                        }
                    }),
                    ...(!drawerOpen && {
                        '&.Mui-selected': {
                            '&:hover': {
                                bgcolor: 'transparent'
                            },
                            bgcolor: 'transparent'
                        }
                    })
                }}>
                {item.icon && <img src={item.icon} width={28} height={28} alt=''
                                   style={{
                                       filter: isSelected || checkPath() ? 'invert(1)' : 'none',
                                       borderRadius: '8px',
                                       paddingRight: 3
                                   }}/>}
                {(drawerOpen || (!drawerOpen && level !== 1)) && (
                    <ListItemText
                        primary={
                            <Typography
                                variant={level > 1 ? "subtitle2" : "h6"}
                                sx={{color: isSelected || checkPath() ? iconSelectedColor : 'white'}}>
                                {item.title}
                            </Typography>
                        }
                    />
                )}
            </ListItemButton>
            {(isOpenChilds || checkPath()) && <Box>{renderChilds()}</Box>}
        </>
    )
}

export default NavItem