import React from 'react'
import {Box, List, Typography} from '@mui/material'
import NavItem from './NavItem'
import {useUnit} from 'effector-react'
import {$menuState} from 'store/models/menu'
import {useCurrentUser} from 'utils/hooks/useCurrentUser'
import {superAdminDeadList, testerPermissionBLockedList} from 'shared/helpers/helpers'

export interface MenuItem {
    type: string
    id: string
    title: string
    url: string
    icon?: any
    target?: string | undefined
    external?: boolean | undefined
    disabled?: boolean | undefined
    children?: MenuItem[]
    isClickable?: boolean
    parent?: string
}

interface NavGroupProps {
    item: {
        title?: string
        icon?: any
        children?: MenuItem[]
    }
}

const NavGroup: React.FC<NavGroupProps> = ({item}) => {
    const menu = useUnit($menuState)
    const {drawerOpen} = menu

    const userData = useCurrentUser()

    const navCollapse = (items: MenuItem[] = []): any => {
        if (!items?.length) return null
        return items?.map((menuItem: MenuItem, idx: number) => {
            if (userData?.role !== 'TESTER' && userData?.role !== 'SUPER_ADMIN' && superAdminDeadList?.[menuItem.id]) return null

            switch (menuItem.type) {
                case 'item':
                    if (userData?.role === 'TESTER' && testerPermissionBLockedList?.[menuItem.id]) return null

                    return <NavItem key={menuItem.id} item={menuItem} level={1}/>
                default:
                    return null
            }
        })
    }

    return (
        <List
            subheader={
                item.title &&
                drawerOpen && (
                    <Box sx={{pl: 3, mb: 1.5}}>
                        <Typography variant="subtitle2" color="textSecondary">
                            {item.title}
                        </Typography>
                    </Box>
                )
            }
            sx={{mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0}}
            role="group"
            aria-label='Main Drawer Navigation'
        >
            {navCollapse(item.children)}
        </List>
    )
}

export default NavGroup