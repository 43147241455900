import { useEffect, useState } from 'react';
const useImageLoaded = (src: string = '') => {
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		const img = new Image();
		img.src = src;
		img.onload = () => {
			setIsLoaded(true)
		};
	}, [src])

	return {
		isLoaded,
		setIsLoaded
	}
};

export default useImageLoaded;
