import React, { ElementType } from 'react'
import { Card, CardContent, CardHeader, Theme, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'

interface MainCardProps {
    component?: ElementType<any, any>
    border?: boolean
    boxShadow?: boolean
    children?: React.ReactNode
    content?: boolean
    contentSX?: object
    darkTitle?: boolean
    elevation?: number
    secondary?: React.ReactNode
    shadow?: string
    sx?: object
    title?: string
    [key: string]: any
}

interface CustomTheme extends Theme {
    customShadows?: {
        z1: string
    }
}


const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' },
}

const MainCard = React.forwardRef(
    (
        {
            component: Component = Card, // Add this line
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            darkTitle,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            ...others
        }: MainCardProps,
        ref
    ) => {
        const theme = useTheme() as CustomTheme
        boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow
        const customShadow = theme.customShadows?.z1 || 'inherit'

        return (
            <Component
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor: '#DEE2E6',
                    boxShadow: boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || customShadow : 'inherit',
                    ':hover': {
                        boxShadow: boxShadow ? shadow || customShadow : 'inherit',
                    },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem',
                    },
                    ...sx,
                }}
            >
                {!darkTitle && title && (
                    <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} action={secondary} />
                )}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant="h3">{title}</Typography>} action={secondary} />
                )}

                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}
            </Component>
        )
    }
)

export default MainCard