import { Skeleton } from "@mui/material";
import { ObjectType } from "shared/helpers/types";

interface IProps {
  variant: 'text' | 'rectangular' | 'rounded' | 'circular',
  condition: boolean,
  children: any,
  height?: number,
  sx?: ObjectType
}

const SkeletonLoad = ({
  variant,
  condition,
  children,
  height = 40,
  sx = {}
}: IProps) => {

  if (condition) return <>{children}</>

  return (
    <Skeleton sx={{...sx}} variant={variant} height={height}>
      {children}
    </Skeleton>
  );
};

export default SkeletonLoad;