import {
    archiveUserGQL,
    createUserGQL,
    getAllUsersGQL,
    getUserByIdGQL,
    getUsersGQL,
    removeUserByIdGQL,
    removeUsersByIdGQL,
    updateUserByIdGQL,
    updateUserPasswordByIdGQL
} from 'queries/users'
import { BASE_URL, Base_Url } from 'shared/helpers/api-routes'
import { ObjectType } from 'shared/helpers/types'
import { BaseApi } from 'utils/Api/baseApi'

export const fetchUsers = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getUsersGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchAllUsers = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getAllUsersGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchUserByID = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getUserByIdGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdateUserByID = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: updateUserByIdGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdateUserAvatarByID = (body: ObjectType) => {
    const http = new BaseApi('');
    return http.post(`${BASE_URL}/user/uploadAvatar`, body, {}, true)
}

export const fetchUpdateUserPasswordByID = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: updateUserPasswordByIdGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchCreateUser = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: createUserGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchRemoveUserById = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: removeUserByIdGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}

export const fetchArchiveUser = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: archiveUserGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchDeleteUsers = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: removeUsersByIdGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}
