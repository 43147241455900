import { createEvent, createStore } from 'effector'
import { ArrayType, ObjectType } from 'shared/helpers/types'
import { fetchAccessFx } from './effects'

interface GlobalState {
    breadCrumbValue: Record<string, string | boolean>
    customerData: ObjectType,
    taskCount: null,
    policyCount: null,
    isFetch: boolean,
    isChecked: boolean,
    isSearchable: boolean,
    isSelectedTableAll: boolean,
    selectedTableRows: ArrayType,
    access: boolean
}

export const $globalState = createStore<GlobalState>({
    breadCrumbValue: {
        waiting: true,
        title: ''
    },
    customerData: {},
    taskCount: null,
    policyCount: null,
    isFetch: false,
    isChecked: false,
    isSearchable: false,
    isSelectedTableAll: false,
    selectedTableRows: [],
    access: false
})

export const setBreadCrumbValue = createEvent<ObjectType>()
export const setPolicyCount = createEvent<ObjectType>()
export const setGlobalState = createEvent<ObjectType>()

$globalState
.on(setBreadCrumbValue, (state, result) => ({
    ...state,
    breadCrumbValue: result
}))

$globalState
.on(setPolicyCount, (state, result) => ({
    ...state,
    policyCount: result.count
}))

$globalState
.on(setGlobalState, (state, {name, value}) => ({
    ...state,
    [name]: value
}))

$globalState
.on(fetchAccessFx.done, (state, {result}) => ({
    ...state,
    access: result
}))