import {createEvent, createStore} from 'effector'
import {UsersType, UserType} from 'shared/model/users/users.type'
import {
    fetchAllUsersFx,
    fetchCreateUserFx,
    fetchRemoveUserByIdFx,
    fetchUpdateUserAvatarByIDFx,
    fetchUpdateUserByIDFx,
    fetchUpdateUserPasswordByIDFx,
    fetchUserByIDFx,
    fetchUsersFx
} from './effects'
import {ArrayType, ObjectType} from 'shared/helpers/types'
import {fetchUserAttachmentsByIDFx} from './attachments/effects'

interface UserState {
    users: UsersType,
    allUsers: UsersType,
    filteredLoggedInUsers: UsersType,
    notCustomerUsers: UsersType,
    selectedUser: UserType,
    error: string,
    status: string,
    attachments: ArrayType,
    total: number,
    avatar: string
}

export const $userState = createStore<UserState>({
    users: [],
    allUsers: [],
    filteredLoggedInUsers: [],
    notCustomerUsers: [],
    selectedUser: {} as UserType,
    error: '',
    status: '',
    attachments: [],
    total: 0,
    avatar: '',
})

export const setUserState = createEvent<ObjectType>({})

$userState
    .on(fetchUsersFx.doneData, (state, {count = 0, data = []}) => ({
        ...state,
        users: data,
        notCustomerUsers: data.filter((user: UserType) => user.role !== 'CUSTOMER'),
        total: count,
        status: 'success',
        error: ''
    }))
    .on(fetchUsersFx.failData, (state, error) => ({
        ...state,
        error: error.message,
        status: 'failure'
    }))

$userState
    .on(fetchAllUsersFx.doneData, (state, {count = 0, data = []}) => ({
        ...state,
        allUsers: data,
    }))
    .on(fetchAllUsersFx.failData, (state, error) => ({
        ...state,
        error: error.message,
        status: 'failure'
    }))

$userState
    .on(fetchUserByIDFx.done, (state, {result}) => ({
        ...state,
        selectedUser: result
    }))
    .on(fetchUserByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message,
    }))

$userState
    .on(fetchUpdateUserByIDFx.done, (state, {result}) => {
        const users = Array.isArray(state.users) ? state.users : []
        return {
            ...state,
            users: users.map(user => {
                if (user.id === result.id) {
                    return {
                        ...user,
                        active: !user.active
                    }
                }
                return user
            }),
        }
    })
    .on(fetchUpdateUserByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message,
    }))

$userState
    .on(fetchUpdateUserPasswordByIDFx.done, (state, {result}) => ({
        ...state,
        selectedUser: result,
        error: ''
    }))
    .on(fetchUpdateUserPasswordByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message,
    }))

$userState
    .on(fetchCreateUserFx.done, (state, {result}) => ({
        ...state,
        users: [...state.users, result],
    }))
    .on(fetchCreateUserFx.failData, (state, error) => ({
        ...state,
        error: error.message,
    }))

$userState
    .on(fetchRemoveUserByIdFx.done, (state, {result}) => {
        let newUsers = structuredClone(state.users)
        if (result.forceDelete) newUsers = newUsers.filter((user: UserType) => user.id !== result.id)
        else {
            newUsers = newUsers.map(user => {
                if (user.id === result.id) {
                    return {
                        ...user,
                        active: !user.active
                    }
                }
                return user
            })
        }

        return ({
            ...state,
            users: newUsers
        })
    })
    .on(fetchRemoveUserByIdFx.failData, (state, error) => ({
        ...state,
        error: error.message,
    }))

$userState
    .on(setUserState, (state, {name, value}) => ({
        ...state,
        [name]: value,
    }))

$userState
    .on(fetchUserAttachmentsByIDFx.done, (state, {result}) => {
        return {
            ...state,
            avatar: result?.path || '',
            attachments: result
        }
    })

$userState
    .on(fetchUpdateUserAvatarByIDFx.done, (state, {result}) => ({
        ...state,
        avatar: result.path || ''
    }))

$userState
    .on(fetchUpdateUserAvatarByIDFx.failData, (state, error) => ({
        ...state,
        error: 'Something went wrong!'
    }))