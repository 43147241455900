import React from 'react'
import { useTheme } from '@mui/material/styles'
import { AppBar, Box, IconButton, Toolbar, useMediaQuery } from '@mui/material'
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'
import Breadcrumbs from 'components/@extended/Breadcrumbs'
import navigation from 'menu-items'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight'

interface HeaderProps {
  open: boolean
  handleDrawerToggle: () => void
}

const Header: React.FC<HeaderProps> = ({
 open,
 handleDrawerToggle
}) => {
  const theme = useTheme()
  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))

  const mainHeader = (
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: '2px' }}>
          <IconButton
              disableRipple
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              color="secondary"
              sx={{ color: 'text.primary', ml: { xs: 0, lg: -2 } }}
          >
            {!open ? <FormatAlignLeftIcon /> : <FormatAlignRightIcon />}
          </IconButton>
          <Breadcrumbs navigation={navigation} />
        </Box>
        <HeaderContent />
      </Toolbar>
  )

  const appBar: any = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    padding: 0,
    sx: {
      borderBottom: `1px solid #D3D3D3`
    }
  }

  return (
      <>
        {!matchDownMD ? (
            <AppBarStyled open={open} {...appBar}>
              {mainHeader}
            </AppBarStyled>
        ) : (
            <AppBar {...appBar}>{mainHeader}</AppBar>
        )}
      </>
  )
}
export default Header