import React, {ReactNode, useMemo} from 'react'
import {CssBaseline, StyledEngineProvider} from '@mui/material'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import Typography from './typography'
import CustomShadows from './shadows'
import Palette from '../assets/palette'
import {TypographyOptions} from '@mui/material/styles/createTypography'

interface ThemeCustomizationProps {
    children?: ReactNode
}

const ThemeCustomization: React.FC<ThemeCustomizationProps> = ({children}) => {
    const theme = Palette('light')
    const themeTypography = Typography({
        fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`
    }) as TypographyOptions
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme])

    const themeOptions: any = useMemo(
        () => ({
            breakpoints: {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536
                }
            },
            direction: 'ltr',
            mixins: {
                toolbar: {
                    minHeight: 60,
                    paddingTop: 8,
                    paddingBottom: 8
                }
            },
            palette: theme.palette,
            customShadows: themeCustomShadows,
            typography: themeTypography
        }),
        [theme, themeTypography, themeCustomShadows]
    )
    const themes = createTheme(themeOptions)

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default ThemeCustomization