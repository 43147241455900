import { createEvent, createStore } from "effector";
import { getLocalMenuState } from "shared/helpers/helpers";

export const initialMenuState = getLocalMenuState()

export const $menuState = createStore(initialMenuState);

export const activeItem = createEvent<string[]>()
export const activeComponent = createEvent<string>()
export const openDrawer = createEvent<boolean>()
export const openComponentDrawer = createEvent<boolean>()

$menuState
  .on(activeItem, (state, result) => ({
    ...state,
    openItem: result,
  }))

$menuState
  .on(activeComponent, (state, result) => ({
    ...state,
    openComponent: result,
  }))

$menuState
  .on(openDrawer, (state, result) => ({
    ...state,
    drawerOpen: result,
  }))

$menuState
  .on(openComponentDrawer, (state, result) => ({
    ...state,
    componentDrawerOpen: result,
  }))