import {createEffect} from 'effector'
import {ObjectType} from 'shared/helpers/types'
import {
    fetchCreateCustomer,
    fetchCustomers,
    fetchCustomersByID,
    fetchDeleteCustomers,
    fetchRemoveCustomerById,
    fetchUpdateCustomerById
} from './services'

export const fetchCustomersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCustomers(body);
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.customers
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchCustomersByIDFx = createEffect(async (id: number) => {
    try {
        const response = await fetchCustomersByID({id})
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.customer
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchCreateCustomerFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCreateCustomer(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchUpdateCustomerByIdFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateCustomerById(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.updateCustomer
    } catch (error: any) {

        throw new Error(error.message)
    }
})

export const fetchRemoveCustomerByIdFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchRemoveCustomerById(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        response.data.removeCustomer.forceDelete = body.forceDelete
        return response.data.removeCustomer
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchDeleteCustomersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchDeleteCustomers(body)
        
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        return response.data.removeCustomers
    } catch (error: any) {
        throw new Error(error?.message)
    }
})