import {useEffect} from 'react'
import {useLocation} from 'react-router'
import {deadList, formatText} from 'shared/helpers/helpers'
import {setBreadCrumbValue} from 'store/models/global'

const useBreadCrumb = (path?: String, waitingFor: boolean = false) => {
    const location = useLocation()

    useEffect(() => {
        const includes = deadList?.some((item: string) => location.pathname.includes(item));
        if (includes) {
            if (path) {
                setBreadCrumbValue({
                    waiting: waitingFor,
                    title: path
                })
            }
        } else {
            setBreadCrumbValue({
                waiting: false,
                title: formatText(location.pathname)
            })
        }
    }, [path, waitingFor, location])
}

export default useBreadCrumb