import React, { Fragment, useMemo } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, Drawer, useMediaQuery } from '@mui/material'
import DrawerContent from './DrawerContent'
import MiniDrawerStyled from './MiniDrawerStyled'
import { drawerWidth } from 'config'
import Logo from 'assets/images/logo/Asset 1@2x-8.png'

interface MainDrawerProps {
    open: boolean
    handleDrawerToggle: () => void
    window?: () => Window
}

const MainDrawer: React.FC<MainDrawerProps> = ({open, handleDrawerToggle, window}) => {
    const theme = useTheme()
    const drawerContent = useMemo(() => <DrawerContent/>, [])
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'))
    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <Box component="nav" sx={{flexShrink: {md: 0}, zIndex: 1300}} aria-label="mailbox folders">
            {!matchDownMD ? (
                <MiniDrawerStyled variant="permanent" open={open}>
                    <Fragment>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                            background: theme.palette.primary.main,
                            p: 3
                        }}>
                            <img src={Logo} alt="Dashboard Logo" width="220" height="40"/>
                        </Box>
                        <Box sx={{
                            background: theme.palette.primary.main,
                            height: '100%',
                        }}>
                            {drawerContent}
                        </Box>
                    </Fragment>
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant="temporary"
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{keepMounted: true}}
                    sx={{
                        display: {xs: 'block', lg: 'none'},
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            backgroundImage: 'none',
                            boxShadow: 'inherit',
                            color: 'white',
                        },
                    }}
                >
                    <Fragment>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '12px',
                            background: theme.palette.primary.main,
                            p: 3
                        }}>
                            <img src={Logo} alt="Dashboard Logo" width="200" height="40"/>
                        </Box>
                        <Box sx={{background: theme.palette.primary.main, height: '100%'}}>
                            {drawerContent}
                        </Box>
                    </Fragment>
                </Drawer>
            )}
        </Box>
    )
}

export default MainDrawer