export const signInGQL = `
    mutation Signin($email: String!, $password: String!) {
        signin(signInInput: {
          email: $email
          password: $password
        }) {
          user {
            id
            firstName
            lastName
            role
            phoneNumber
            email
            active
            description
            verifyEmail
            codeExpirationDate
          }
        }
    }
`

export const checkAuthGQL = `
  query { 
    checkAuth {
      user {
        id
        firstName
        lastName
        role
        phoneNumber
        email
        active
        description
        verifyEmail
        codeExpirationDate
      }
    }
  }
`

export const logoutGQL = `
  query { logout }
`

export const sendCodeGQL = `
  mutation SendCode( 
    $email: String!
  ){
    sendCode(
      email: $email
    )
  }
`

export const validateCodeGQL = `
  mutation ValidateCode( 
    $code: String!
  ){
    validateCode(
      code: $code
    )
  }
`

export const resetPasswordGQL = `
  mutation ResetPassword( 
    $password: String!
    $email: String!
  ){
    resetPassword(
      password: $password
      email: $email
    )
  }
`

export const getAuthUriGQL = `
    query Auth {
        getAuthUri {
            uri
        }
    }
`

export const signUpGQL = `
    mutation Signup(
        $email: String!
        $password: String!
        $passwordConfirmation: String!
        $firstName: String
        $lastName: String
    ) {
        userRegistration(registrationUserInput: {
          email: $email
          password: $password
          passwordConfirmation: $passwordConfirmation
          firstName: $firstName
          lastName: $lastName
        }) 
      {
        id
        firstName
        lastName
        email
        verifyEmail
      }
    }
`

export const verifyUserEmailCodeGQL = `
  mutation VerifyUserEmail( 
    $code: String!
    $email: String!
  ){
    verifyUserEmail(
        email: $email
        code: $code
    ){
        id
        firstName
        lastName
        email
        verifyEmail
    }
  }
`

export const resendCodeConfirmationGQL = `
  mutation ReSendCodeConfirmation( 
    $email: String!
  ){
    resendCodeConfirmation(
      email: $email
    )
  }
`