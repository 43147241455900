import {createEvent, createStore} from 'effector'
import {InsurerType} from 'shared/model/insurers/insurers.type'
import {
    fetchCreateInsurerFx,
    fetchDeleteInsurersFx,
    fetchInsurerByIDFx,
    fetchInsurerLevelsByIDFx,
    fetchInsurersFx,
    fetchRemoveInsurerByIdFx,
    fetchUpdateInsurerAvatarByIDFx,
    fetchUpdateInsurerByIDFx
} from './effects'
import {ObjectType} from 'shared/helpers/types'
import {fetchInsurerAvatarByIDFx} from './attachments/effects'
import {defaultPerPage} from 'shared/helpers/helpers'

interface InsurerState {
    insurers: InsurerType[],
    total: number,
    selectedInsurer: any,
    selectedInsurerAddress: any,
    error: string,
    isInactiveEnabled: boolean,
    avatar: string
}

export const setInsurersState = createEvent<ObjectType>()
export const $insurerState = createStore<InsurerState>({
    insurers: [],
    total: 0,
    selectedInsurer: {} as InsurerType,
    selectedInsurerAddress: {} as InsurerType,
    error: '',
    isInactiveEnabled: false,
    avatar: ''
})

$insurerState
    .on(fetchInsurersFx.doneData, (state, {count = 0, data = []}) => ({
        ...state,
        total: count,
        insurers: data
    }))
    .on(fetchInsurersFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(fetchInsurerByIDFx.done, (state, {result}) => ({
        ...state,
        selectedInsurer: result
    }))
    .on(fetchInsurerByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(fetchInsurerLevelsByIDFx.done, (state, {result}) => ({
        ...state,
        selectedInsurerAddress: result
    }))
    .on(fetchInsurerLevelsByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(fetchUpdateInsurerByIDFx.done, (state, {result}) => {
        const insurers = Array.isArray(state.insurers) ? state.insurers : []
        return {
            ...state,
            insurers: insurers.map(insurer => {
                if (insurer.id === result.id) {
                    return {
                        ...insurer,
                        active: !insurer.active
                    }
                }
                return insurer
            })
        }
    })
    .on(fetchUpdateInsurerByIDFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(fetchCreateInsurerFx.done, (state, {result}) => ({
        ...state,
        ...(state.total < defaultPerPage && {insurers: [...state.insurers, result]}),
        total: state.total + 1
    }))
    .on(fetchCreateInsurerFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(fetchRemoveInsurerByIdFx.done, (state, {result}) => ({
        ...state,
        insurers: state.insurers.filter((insurer: InsurerType) => insurer.id !== result.id),
        total: state.total - 1
    }))
    .on(fetchRemoveInsurerByIdFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(fetchDeleteInsurersFx.done, (state, {result}) => ({
        ...state,
        insurers: state.insurers.filter((insurer: InsurerType) => insurer.id !== result.id),
        total: state.total - 1 // it will work WRONG
    }))
    .on(fetchDeleteInsurersFx.failData, (state, error) => ({
        ...state,
        error: error.message
    }))

$insurerState
    .on(setInsurersState, (state, {name, value}) => ({
        ...state,
        [name]: value
    }))

$insurerState
    .on(fetchUpdateInsurerAvatarByIDFx.done, (state, {result}) => ({
        ...state,
        avatar: result.path || ''
    }))

$insurerState
    .on(fetchUpdateInsurerAvatarByIDFx.failData, (state) => ({
        ...state,
        error: 'Something went wrong!'
    }))

$insurerState
    .on(fetchInsurerAvatarByIDFx.done, (state, {result}) => ({
        ...state,
        avatar: result?.path || null
    }))