import CustomersIcon from 'assets/images/sidebar/Customers.svg'
import InsurersIcon from 'assets/images/sidebar/Insurers.svg'
// import InsuranceCategories from 'assets/images/sidebar/InsuranceCategories.svg'
import LayerIcon from 'assets/images/sidebar/Layer_icon.svg'
import OpportunitiesIcon from 'assets/images/sidebar/Opportunities.svg'
import PaymentsIcon from 'assets/images/sidebar/Payments.svg'
import SanctionsIcon from 'assets/images/sidebar/Sanctions.svg'
import TasksIcon from 'assets/images/sidebar/Tasks.svg'
import UsersIcon from 'assets/images/sidebar/Users.svg'
import ProductsIcon from 'assets/images/sidebar/Products.svg'
// import ProductsDetailsIcon from 'assets/images/sidebar/ProductsDetails.svg'
// import QuestionLibraryIcon from 'assets/images/sidebar/QuestionLibrary.svg'
// import FormsIcon from 'assets/images/sidebar/Forms.svg'
import Info from 'assets/images/sidebar/Info.svg'
import Edit from 'assets/images/sidebar/Edit.svg'
import Help from 'assets/images/sidebar/Help.svg'
import ProfileIcon from 'assets/images/sidebar/Profile.svg'

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'tasks',
            title: 'Tasks',
            type: 'item',
            url: '/tasks',
            icon: TasksIcon
        },
        {
            id: 'accounts',
            title: 'Accounts',
            type: 'item',
            url: '/accounts',
            icon: CustomersIcon
        },
        {
            id: 'opportunities',
            title: 'Opportunities',
            type: 'item',
            url: '/opportunities',
            icon: OpportunitiesIcon
        },
        {
            id: 'payments',
            title: 'Payments',
            type: 'item',
            url: '/payments',
            icon: PaymentsIcon
        },
        {

            id: 'customers-due-diligence',
            title: 'Customer due Diligence',
            type: 'item',
            url: '/customer-due-diligence',
            icon: SanctionsIcon
        },
        {
            id: 'insurers',
            title: 'Insurers',
            type: 'item',
            url: '/insurers',
            icon: InsurersIcon
        },
        {
            id: 'insurance_categories',
            title: 'Insurance Categories',
            type: 'item',
            url: '/insurance-categories',
            icon: LayerIcon
        },
        // {
        //   id: 'telematics',
        //   title: 'Telematics',
        //   type: 'item',
        //   url: '/telematics'
        // },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/users',
            icon: UsersIcon
        },
        {
            id: 'my-profile',
            title: 'My Profile',
            type: 'item',
            url: '/my-profile',
            icon: ProfileIcon
        },
        // {
        //   id: 'back-office',
        //   title: 'Back Office',
        //   type: 'item',
        //   url: '/back-office'
        //   // breadcrumbs: false
        // },
        {
            id: 'products',
            title: 'Products',
            type: 'item',
            isClickable: true,
            icon: ProductsIcon,
            children: [
                {
                    id: 'details',
                    title: 'Products details',
                    parent: 'products',
                    type: 'item',
                    url: '/products-details',
                    icon: Info
                },
                {
                    id: 'library',
                    title: 'Question library',
                    type: 'item',
                    parent: 'products',
                    url: '/question-library',
                    icon: Help
                },
                {
                    id: 'forms',
                    title: 'Forms',
                    type: 'item',
                    parent: 'products',
                    url: '/forms',
                    icon: Edit
                },
            ]
        },
    ]
}

export default pages