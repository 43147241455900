import { forwardRef, ReactNode, Ref } from 'react'
import { Fade, Box, Grow } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'

interface TransitionsProps {
  children: ReactNode
  position?: string
  type: 'grow' | 'fade'
}

const Transitions = forwardRef(
    ({ children, position, type, ...others }: TransitionsProps, ref: Ref<HTMLDivElement>) => {
      let positionSX = {
        transformOrigin: '0 0 0',
      }

      switch (position) {
        case 'top-right':
        case 'top':
        case 'bottom-left':
        case 'bottom-right':
        case 'bottom':
        case 'top-left':
        default:
          positionSX = {
            transformOrigin: '0 0 0',
          }
          break
      }

      return (
          <Box ref={ref}>
            {type === 'grow' && (
                <Grow {...others as TransitionProps}>
                  <Box sx={positionSX}>{children}</Box>
                </Grow>
            )}
            {type === 'fade' && (
                <Fade
                    {...others}
                    timeout={{
                      appear: 0,
                      enter: 300,
                      exit: 150,
                    }}
                >
                  <Box sx={positionSX}>{children}</Box>
                </Fade>
            )}
          </Box>
      )
    }
)

export default Transitions