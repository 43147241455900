import { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Box, Toolbar, useMediaQuery } from '@mui/material'
import Drawer from './Drawer'
import Header from './Header'
import { $menuState, openDrawer } from 'store/models/menu'
import { useUnit } from 'effector-react'
import { $authState } from 'store/models/auth'
import { getCookie } from 'shared/helpers/helpers'
import { fetchAccessFx } from 'store/models/global/effects'

const MainLayout = () => {
  const theme = useTheme()
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'))
  const location = useLocation()
  const { isLoggedIn } = useUnit($authState);
  const { drawerOpen } = useUnit($menuState)
  const [open, setOpen] = useState(drawerOpen)
  const loadingAccess = useUnit(fetchAccessFx.pending)
  const handleDrawerToggle = () => {
    setOpen(!open)
    openDrawer(!open)
  }

  useEffect(() => {
    setOpen(!matchDownLG)
    openDrawer(!matchDownLG)
  }, [matchDownLG])

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen)
  }, [drawerOpen])

  if (loadingAccess) return ''

  if (!isLoggedIn && !getCookie('accessToken') && !location.pathname.includes('/f/')) return <Navigate to='/login' />

  if (location?.pathname === '/') return <Navigate to='/tasks' />

  return (
    location.pathname.includes('/f/') ? <Outlet /> : <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 1.5, sm: 3 } }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  )
}

export default MainLayout