import {
    createInsurerGQL,
    getInsurerByIDGQL,
    getInsurerLevelsByIDGQL,
    getInsurersGQL,
    removeInsurerByIdGQL,
    removeInsurersByIdGQL,
    updatedInsurerGQL,
} from 'queries/insurers'
import {BASE_URL, Base_Url} from 'shared/helpers/api-routes'
import {ObjectType} from 'shared/helpers/types'
import {BaseApi} from 'utils/Api/baseApi'

export const fetchInsurers = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getInsurersGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchInsurerByID = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getInsurerByIDGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchInsurerLevelsByID = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: getInsurerLevelsByIDGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdateInsurerByID = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: updatedInsurerGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchCreateInsurer = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: createInsurerGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdateInsurerAvatarByID = (body: ObjectType) => {
    const http = new BaseApi('');
    return http.post(`${BASE_URL}/insurer/uploadAvatar`, body, {}, true)
}

export const fetchRemoveInsurerById = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: removeInsurerByIdGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchDeleteInsurers = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: removeInsurersByIdGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}
