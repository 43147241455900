import formatDate from './formattedDate'
import {ArrayType, ObjectType} from './types'

export const appColor = '#8958FE'
export const defaultPerPage = 10
export const maxFileSize = 10

export const deadUserList = ['hovsepyanmanuk02@gmail.com', 'sergeykostandyan4@gmail.com', 'n.lhoste@arizeo.com', 'aksel123@gmail.com', 'ulrich-zink@mail.ru', 'lusinegevorgyan188@gmail.com']

export const copyText = (text: string) => {
    try {
        const textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        return true
    } catch (error) {
        console.log(error)
        return false
    }
}

export const randomString = (_len: number) => {
    let _res = ''
    const _chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = _chars.length
    let counter = 0
    while (counter < _len) {
        _res += _chars.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
    }
    return _res
}

export const trim = (str: string) => str.replace(/^\s+|\s+$/g, '')

export const formatText = (text: string) => text.replace(/[/\-.]/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())

export const deadList = [
    'account-details',
    'insurer-details',
    'user-details',
    'new-insurer',
    'new-account',
    'new-user',
    'new-task',
    'new-address',
    'task-details',
    'forms/',
    'products-details/',
    '404'
]

export const setCookie = (name: string, value: any, days: number) => {
    var expires = ''
    if (days) {
        var date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = '; expires=' + date.toUTCString()
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/'
}

export const getCookie = (name: string) => {
    var nameEQ = name + '='
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i]
        while (c.charAt(0) === ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
    }
    return null
}

export const eraseCookie = (name: string) => {
    document.cookie = name + `=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`
}

export const navigateFromRisk = (location: ObjectType, id: number, newAddress?: string) => {
    const {pathname} = location
    let newPath = pathname

    if (newAddress) {
        newPath = newAddress
    } else {
        const regexRisk = new RegExp(/\/risk\/\d+/)
        const regexNewAddress = new RegExp(/\/new-address/)
        const regexAddress = new RegExp(/\/address\/\d+/)

        if (regexRisk.test(pathname)) {
            newPath = pathname.replace(regexRisk, '')
        } else if (regexNewAddress.test(pathname)) {
            newPath = pathname.replace(regexNewAddress, '')
        } else if (regexAddress.test(pathname)) {
            newPath = pathname.replace(regexAddress, '')
        }
    }

    return `${newPath}?tab=${id}`
}

export const ellipsis3InlineStyles = {
    WebkitLineClamp: 3,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    maxWidth: '200px'
}

export const ellipsisInlineStyles = {
    WebkitLineClamp: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    maxWidth: '200px'
}

export const disabledStyle = {
    color: '#8c8c8c !important',
    boxShadow: 'none !important',
    backgroundColor: '#f1f3f5 !important'
}

export const ellipsis2InlineStyles = {
    WebkitLineClamp: 2,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    maxWidth: '150px'
}

export const getLocalMenuState = () => {
    const defaultMenuState = {
        openItem: ['tasks'],
        defaultId: 'tasks',
        openComponent: 'buttons',
        drawerOpen: false,
        componentDrawerOpen: true
    }
    try {
        const serializedState = localStorage.getItem('menuState')
        if (serializedState === null) {
            localStorage.setItem('menuState', JSON.stringify(defaultMenuState))
            return defaultMenuState
        }
        return JSON.parse(serializedState)
    } catch (err) {
        return defaultMenuState
    }
}

export const getConvertedDateFormat = (date: Date) => {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('/')
}

export const dispatchCustomEvent = (name: string, data: any) => {

    const event = new CustomEvent(name, {
        detail: data
    })

    return window.dispatchEvent(event)
}

export const getCustomerContactMessage = (recommendationFreeType: string, demandsFreeType: string, publishQuoteList: ArrayType, isPrimaryContactInfo: ObjectType | undefined, status: boolean) => {

    if (status) return {
        type: 'success',
        message: 'The published body can now be viewed on the Customer Portal! User has been notified to his phone number and/or email.'
    }
    else if (!recommendationFreeType || !demandsFreeType) {
        return {
            type: 'error',
            message: 'You need to fill out the recommendation and demands and needs fields in order to publish body.'
        }
    } else if (!publishQuoteList.length) {
        return {
            type: 'error',
            message: 'You need to select at least one quote to publish.'
        }
    } else if (!isPrimaryContactInfo) {
        return {
            type: 'error',
            message: 'You need to have a primary contact to have access to the Customer Portal. You can create a primary contact here immediately.'
        }
    }
    return {
        type: '',
        message: ''
    }
}

export const getRiskActiveTabs = (categoryTypes: string) => {
    return categoryTypes?.split(',')?.reduce((acc: ObjectType, item: string) => {
        acc[item] = true
        return acc
    }, {}) || {}
}

export const getQuestionBooleanValue = (value: string, defaultValue: any = '') => {
    if (String(value)?.toLowerCase() === 'true') return 'Yes'
    if (String(value)?.toLowerCase() === 'false') return 'No'
    return defaultValue || false
}

export const getAnswerBooleanValue = (value: boolean) => {
    if (value) return 'Yes'
    if (!value) return 'No'
}

export const getCheckedPasswordRequirements = (password: string, reqs: ObjectType) => {
    const clonedReqs = structuredClone(reqs)
    if (password.length >= 8) {
        if (!clonedReqs.eightLength) clonedReqs.eightLength = true
    } else clonedReqs.eightLength = false

    if (password.toUpperCase() !== password) {
        if (!clonedReqs.lowerCase) clonedReqs.lowerCase = true
    } else clonedReqs.lowerCase = false

    if (password.toLowerCase() !== password) {
        if (!clonedReqs.upperCase) clonedReqs.upperCase = true
    } else clonedReqs.upperCase = false

    if (/\d/.test(password)) {
        if (!clonedReqs.number) clonedReqs.number = true
    } else clonedReqs.number = false

    // eslint-disable-next-line no-useless-escape
    const chars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    if (chars.test(password)) {
        if (!clonedReqs.character) clonedReqs.character = true
    } else clonedReqs.character = false

    return clonedReqs
}

export const superAdminDeadList: Record<string, boolean> = {
    'insurance_categories': true
}

export const testerPermissionBLockedList: Record<string, boolean> = {
    "users": true,
    "payments": true,
    'customers-due-diligence': true
}

export const downloadSpecificFile = (file: Blob | string, name: string) => {
    const a = document.createElement('a');

    if (file instanceof Blob) {
        a.href = URL.createObjectURL(file);
    } else {
        a.href = file;
    }

    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    if (file instanceof Blob) {
        URL.revokeObjectURL(a.href);
    }

    return true;
}

export const getFilteredQuestions = (questions: ObjectType, categoryTypes: string = '') => {
    if (!categoryTypes) return questions
    const splittedTypes = categoryTypes.split(',') || []
    return Object.keys(questions).reduce((acc: ObjectType, item: string) => {
        if (splittedTypes.includes(item)) {
            const clonedItems = structuredClone(questions[item]);
            clonedItems.forEach((it: ObjectType) => {
                if (it?.tabName !== "datePicker") return it;
                const clonedItem = structuredClone(it)
                const newValue = clonedItem?.filled?.value ? clonedItem.filled.value : '';
                const newItemValue = newValue ? formatDate(newValue) : '';
                return it.filled.value = newItemValue
            })
            acc[item] = clonedItems
        }

        // if (splittedTypes.includes(item)) acc[item] = questions[item]

        return acc
    }, {}) || {}
}

export const adjustToUTCAndFormat = (date: string | Date): string => {
    const newDate = new Date(date)
    return new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString()
}

export default function convertToBase64(imgPath: string, callback: (base64Data: string) => void) {
    const reader = new FileReader()
    reader.onloadend = function () {
        if (reader.result) {
            const base64String = (reader.result as string).split(',')[1]
            callback(base64String)
        }
    }

    fetch(imgPath)
        .then(response => response.blob())
        .then(blob => reader.readAsDataURL(blob))
}