import {
    createCustomerGQL,
    getCustomerByIDGQL,
    getCustomersGQL,
    removeCustomerByIdGQL,
    removeCustomersGQL,
    updateCustomerByIdGQL,
} from 'queries/customer'

import {Base_Url} from 'shared/helpers/api-routes'
import {ObjectType} from 'shared/helpers/types'
import {BaseApi} from 'utils/Api/baseApi'

//Fetch Customer
export const fetchCustomers = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getCustomersGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchCustomersByID = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: getCustomerByIDGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchCreateCustomer = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: createCustomerGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchUpdateCustomerById = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: updateCustomerByIdGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchRemoveCustomerById = (body: ObjectType) => {
    const http = new BaseApi('')
    const gqlPayload = {
        query: removeCustomerByIdGQL,
        variables: body
    }
    return http.post(Base_Url, gqlPayload)
}

export const fetchDeleteCustomers = (body: ObjectType) => {
    const http = new BaseApi('');
    const gqlPayload = {
        query: removeCustomersGQL,
        variables: body
    };
    return http.post(Base_Url, gqlPayload)
}
