import Routes from 'routes'
import ThemeCustomization from './themes'
import 'App.css'
import ScrollTop from './components/ScrollTop'
import {useLayoutEffect} from 'react'
import {resetAuthState, setSomeAuthState} from 'store/models/auth'
import {checkAuthFx, fetchAuthUserAttachmentsByIDFx} from 'store/models/auth/effects'
import {getCookie} from 'shared/helpers/helpers'
import {useUnit} from 'effector-react'
import {fetchAccessFx} from 'store/models/global/effects'
import {useNavigate} from 'react-router'
import TagManager from 'react-gtm-module'
import {AttachmentsType} from 'queries/insurers'

const tagManagerArgs = {
    gtmId: 'GTM-P5QCPBBG'
}
TagManager.initialize(tagManagerArgs)

const App = () => {
    const loading = useUnit(checkAuthFx.pending)
    const loadingAccess = useUnit(fetchAccessFx.pending)

    const navigate = useNavigate()
    const checkAuthentification = async () => {
        const access = await fetchAccessFx({name: 'access'})
        if (access === 'false') return navigate('/unavailable')

        const accessToken = getCookie('accessToken')
        if (!accessToken) return resetAuthState()

        setSomeAuthState({name: 'isLoggedIn', value: true})

        const user = await checkAuthFx()
        if (!user?.id) return resetAuthState()

        await fetchAuthUserAttachmentsByIDFx({id: user.id, type: AttachmentsType.AVATAR})
        setSomeAuthState({name: 'user', value: user})
    }

    useLayoutEffect(() => {
        checkAuthentification()
    }, [])

    if (loading || loadingAccess) return null

    return (
        <ThemeCustomization>
            <ScrollTop>
                <Routes/>
            </ScrollTop>
        </ThemeCustomization>
    )
}

export default App
