import { fetchUserAttachmentsByIDGQL } from "queries/users";
import { Base_Url } from "shared/helpers/api-routes";
import { ObjectType } from "shared/helpers/types";
import { BaseApi } from "utils/Api/baseApi";

export const fetchUserAttachmentsByID = (body: ObjectType) => {
  const http = new BaseApi('');
  const gqlPayload = {
    query: fetchUserAttachmentsByIDGQL,
    variables: body
  };
  return http.post(Base_Url, gqlPayload)
}