import { useUnit } from 'effector-react'
import { Navigate, Outlet } from 'react-router-dom'
import { $authState } from 'store/models/auth'

const MinimalLayout = () => {

  const { isLoggedIn } = useUnit($authState);

  if (isLoggedIn) return <Navigate to='/tasks' />;

  return <Outlet />
}
export default MinimalLayout