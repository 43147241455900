interface Colors {
    purple: string[]
    blue: string[]
    red: string[]
    gold: string[]
    cyan: string[]
    green: string[]
    grey: string[]
}

const Theme = (colors: Colors) => {
    const {purple, blue, red, gold, cyan, green, grey} = colors
    const greyColors = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    }
    const contrastText = '#fff'

    return {
        primary: {
            light: '',
            lighter: blue[0],
            main: '#0C1231',
            dark: purple[6],
            darker: purple[8],
            contrastText
        },
        secondary: {
            light: greyColors[300],
            main: '#8958FE',
        },
        tertiary: {
            light: greyColors[300],
            main: '#60C1FF',
        },
        error: {
            light: red[2],
            main: "#dc2020",
            dark: red[7],
        },
        warning: {
            light: gold[3],
            main: '#f59f00',
            dark: gold[7],
        },
        info: {
            lighter: cyan[0],
            light: cyan[3],
            main: cyan[5],
            dark: cyan[7],
            darker: cyan[9],
            contrastText
        },
        success: {
            lighter: green[0],
            light: green[3],
            main: green[5],
            dark: green[7],
            darker: green[9],
            contrastText
        },
        grey: greyColors
    }
}

export default Theme