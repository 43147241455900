import {createEffect} from "effector";
import {ObjectType} from "shared/helpers/types";
import {
    fetchAllUsers,
    fetchArchiveUser,
    fetchCreateUser,
    fetchDeleteUsers,
    fetchRemoveUserById,
    fetchUpdateUserAvatarByID,
    fetchUpdateUserByID,
    fetchUpdateUserPasswordByID,
    fetchUserByID,
    fetchUsers
} from "./services";
import {$authState, setSomeAuthState} from "../auth"

export const fetchUsersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUsers(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.users || []
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchAllUsersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchAllUsers(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response?.data?.users || []
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchUserByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUserByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.user
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchUpdateUserByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateUserByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        const {user} = $authState.getState()

        if (user.id === response?.data?.updateUser?.id) {
            setSomeAuthState({name: 'user', value: response?.data?.updateUser})
        }

        return response?.data?.updateUser || {}
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchUpdateUserAvatarByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateUserAvatarByID(body)
        if (response.errors || response.message) {
            throw new Error(response.errors?.[0]?.message || response.message)
        }
        const {user} = $authState.getState()

        if (response.userId === user.id) {
            setSomeAuthState({name: 'avatar', value: response.path})
        }
        return response || {}
    } catch (error: any) {
        throw new Error(error?.message)
    }
})

export const fetchUpdateUserPasswordByIDFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchUpdateUserPasswordByID(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data.updatePassword
    } catch (error: any) {
        throw new Error(error)
    }
})

export const fetchCreateUserFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchCreateUser(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        return response.data
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchRemoveUserByIdFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchRemoveUserById(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }
        response.data.removeUser.user.forceDelete = body.forceDelete
        return response.data.removeUser.user
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchArchiveUserFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchArchiveUser(body)
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        return response.data.archiveUser
    } catch (error: any) {
        throw new Error(error.message)
    }
})

export const fetchDeleteUsersFx = createEffect(async (body: ObjectType) => {
    try {
        const response = await fetchDeleteUsers(body)
        
        if (response.errors) {
            throw new Error(response.errors?.[0]?.message)
        }

        return response.data.removeUsers
    } catch (error: any) {
        throw new Error(error?.message)
    }
})