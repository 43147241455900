import React from 'react'
import { alpha, styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import SimpleBar from 'simplebar-react'
import { BrowserView, MobileView } from 'react-device-detect'

type SimpleBarStyleProps = {
    timeout?: number
    clickOnTrack?: boolean
    sx?: Record<string, any>
}

const SimpleBarStyle = styled(SimpleBar)<SimpleBarStyleProps>(({theme}) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: alpha(theme.palette.grey[500], 0.48),
        },
        '&.simplebar-visible:before': {
            opacity: 1,
        },
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 10,
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 6,
    },
    '& .simplebar-mask': {
        zIndex: 'inherit',
        backgroundColor: '#0C1231 !important'
    },
    '& .simplebar-placeholder': {
        display: 'none !important',
    },
}))

export default function SimpleBarScroll({children, sx, ...other}: {
    children: React.ReactNode;
    sx?: Record<string, any>
}) {
    return (
        <>
            <BrowserView>
                <SimpleBarStyle timeout={500} clickOnTrack={false} sx={sx} {...other}>
                    {children}
                </SimpleBarStyle>
            </BrowserView>
            <MobileView>
                <Box sx={{overflowX: 'auto', ...sx}} {...other}>
                    {children}
                </Box>
            </MobileView>
        </>
    )
}